import { useCardano, ConnectWalletButton } from '@cardano-foundation/cardano-connect-with-wallet';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import SkydreamLogo from '../assets/skydream-logo.png'

export const Navigation = (props) => {
  const { isConnected } = useCardano();

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container-fluid align-middle'>
        <div className='navbar-header' style={{paddingTop: 10}}>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            <img alt="Skydream-logo" src={SkydreamLogo} width="110" style={{marginTop:-35}} />
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          {/* OPTIMIZE */}
          { isMobile ?
            <ul className='nav navbar-nav navbar-right' style={{height:330}}>
              { isConnected ?
                <li style={{height: 72, paddingTop: 10}}>
                  <Link to="/device"> Connect to Game</Link>
                </li> : <></>
              }
              <li>
                <ConnectWalletButton />
              </li>
              <li>
                <span className='icon-bar'></span>{' '}
              </li>
            </ul> :
            <ul className='nav navbar-nav navbar-right'>
              { isConnected ?
                <li style={{height: 72, paddingTop: 10}}>
                  <Link to="/device"> Connect to Game</Link>
                </li> : <></>
              }
              <li>
                <ConnectWalletButton />
              </li>
              <li>
                <span className='icon-bar'></span>{' '}
              </li>
            </ul>
          }
          {/* OPTIMIZE */}
        </div>
      </div>
    </nav>
  )
}
