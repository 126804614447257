export const Footer = () => {
  return (
    <div id="footer">
       <div className='container text-center'>
          <p>
            &copy; {new Date().getFullYear()} {' '}
            <a href='https://www.diing.com/' rel='nofollow noreferrer' target="_blank">
              Diing, Inc.
            </a>
          </p>
        </div>
    </div>
  )
}
